import * as selectOptions from "@/helpers/selectOptions";
import { correctLineOfBusiness } from "@/forms/utils";

export const tableColumnsListCompanies = {
  companyName: {
    formatDataKey: "Company Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0
  },
  companyNumber: {
    formatDataKey: "Number",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 1,
    colWidth: 130
  },
  NAICCD: {
    formatDataKey: "NAICCD",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3,
    colWidth: 120
  },
  companyLineOfBusiness: {
    formatDataKey: "Line Of Business",
    formatDataValue: (arg: string) => formatLOB(arg as any),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userRoles,
    position: 4,
    colWidth: 200
  },
  site: {
    formatDataKey: "Site",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    simpleFilter: selectOptions.userStatus,
    position: 5,
    colWidth: 120
  }
};

function formatLOB(linesOfBusiness: string[]) {
  const formattedLOBs = linesOfBusiness.map(lob => {
    return correctLineOfBusiness(lob);
  });
  return formattedLOBs.toString();
}
