
import Vue from "vue";
import { tableColumnsListAccreditedBuilder } from "./ListAccreditedBuilder";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import {
  accreditedBuilderMapActions,
  accreditedBuilderMapGetters
} from "@/store/modules/accreditedBuilder";

interface IAccreditedBuilder {
  tableActions: any[];
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  accreditedBuilder: string;
  tableRowActions: any[];
  loadingText: string;
}
interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}
export default Vue.extend({
  name: "listAccreditedBuilders",
  components: {
    NoResultsFound
  },
  data(): IAccreditedBuilder {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      loading: false,
      accreditedBuilder: "",
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...accreditedBuilderMapActions([
      "getAccreditedBuilders",
      "deleteAccreditedBuilder"
    ]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createAccreditedBuilder":
          this.$router.push(`/admin/accreditedBuilder/create`);
          break;
        case "accredited-builder-bulk-activities":
          this.$router
            .push(`/admin/accreditedBuilder/activities`)
            .catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading accredited builders. Please wait...";
        this.$emit("scrollTop");
        await this.getAccreditedBuilders({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/${_id}/accreditedBuilder`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/accreditedBuilder/create`);
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/${data._id}/accreditedBuilder`);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneCreditedBuilder(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting Accredited Builder. Please wait...";
        await this.deleteAccreditedBuilder(this.accreditedBuilder);
        this.$modal.hide("accreditedBuilderDeleteModal");
        this.$appNotifySuccess("Accredited Builder Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    deleteCallback(accreditedBuilder: any): void {
      this.accreditedBuilder = accreditedBuilder;
      this.$modal.show("accreditedBuilderDeleteModal");
    }
  },
  computed: {
    ...accreditedBuilderMapGetters([
      "accreditedBuilders",
      "getTotalRecords",
      "getAccreditedBuildersLinks"
    ]),
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "accredited-builder-bulk-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    },
    tableColumns(): ITableWrapperColumns {
      return tableColumnsListAccreditedBuilder;
    }
  }
});
