
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import Vue from "vue";
import { tableColumnsListCompanies } from "./CompaniesTable";
import { ITableWrapperColumns } from "@/types";
import CompanyTableOperations from "./CompanyTableOperations.vue";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { cloneDeep } from "lodash";

interface ICompanyCompanyData {
  tableActions: any[];
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  companyId: string;
  tableRowActions: any[];
  loadingText: string;
}
export default Vue.extend({
  name: "listCompanies",
  components: {
    NoResultsFound
  },
  data(): ICompanyCompanyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      loading: false,
      companyId: "",
      tableRowActions: [],
      loadingText: ""
    };
  },
  methods: {
    ...companiesMapActions(["getCompanies", "deleteCompany"]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createNewCompany":
          this.$router.push(`/admin/companies/create`);
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading companies. Please wait...";
        this.$emit("scrollTop");
        await this.getCompanies({
          query: { ...queryObject }
        });
      } catch (error) {
        console.error(error);
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/companies/${_id}`).catch(() => {});
    },
    async createCallback(): Promise<any> {
      this.$router.push(`/admin/companies/create`);
    },
    getOperationsTemplate(): any {
      return CompanyTableOperations;
    },
    //
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "duplicate":
          //do nothing
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/companies/${data._id}`);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    // delete company from here

    async deleteOneCompany(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting company. Please wait...";
        await this.deleteCompany(this.companyId);

        this.$appNotifySuccess("Company Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    //delete modal
    async deleteCallback(companyId: any): Promise<void> {
      try {
        this.companyId = companyId;
        this.$modal.show("companiesDeleteModal");
      } catch (error) {
        // delete disabled
      }
    }
  },
  computed: {
    ...companiesMapGetters([
      "companies",
      "getTotalRecords",
      "getCompaniesLinks"
    ]),
    tableColumns(): ITableWrapperColumns {
      return cloneDeep(tableColumnsListCompanies);
    }
  }
});
