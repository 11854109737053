
import Vue from "vue";
export default Vue.extend({
  props: {
    row: {
      type: Object
    }
  },
  methods: {
    eventHandler(name: string) {
      this.$emit("emitEvent", { name, data: this.row });
    }
  }
});
